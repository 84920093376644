.search_input {
  border-width: 0;
  background-color: #EFEFEF;
  border-radius: 4px;
  color: #f5f5f5;



  .ant-input {
    background-color: #EFEFEF;
    width: 206px;
  }
}



.search_header {
  .title {
    font-weight: 600;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.8);
  }

  // .ant-input-affix-wrapper:hover {
  //   border-color: transparent !important;
  // }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-right-width: 0;
  }

  .ant-input-affix-wrapper-focused,
  .ant-input-affix-wrapper-focus {
    border-color: transparent !important;
    box-shadow: 0 0 0 0 transparent;
  }
}

.ant-divider-horizontal {
  margin: 12px 0;
}