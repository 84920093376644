body {
  margin: 0;
}

#root {
  height: 100%;
}

//状态背景颜色

$Processing: #1890ff; //未中标
$Success: #52c41a; //中标
$cyan: #13c2c2; //中标待确认
$Warning: #faad14; //已驳回
$Default: #dcdcdc; //已作废
$color: #fff;

// 已驳回
.Rejected {
  background-color: $Warning;
  color: $color;
  padding: 4px 12px;
}

//中标待确认
.zbdqr {
  color: $color;
  padding: 4px 12px;
  background-color: $cyan;
}

//已作废
.Voided {
  color: $color;
  padding: 4px 12px;
  background-color: $Default;
}

//中标
.winATender {
  color: $color;
  padding: 4px 12px;
  background-color: $Success;
}

//未中标
.loseAbid {
  color: $color;
  padding: 4px 12px;
  background-color: $Processing;
}

.ant-modal .ant-modal-footer {
  text-align: center;
}

:global {
  .ou {
    background: rgb(242, 247, 247, 0.2) !important;
  }

  .ji {
    // background: rgb(251, 250, 251) !important;
    background: rgb(250, 250, 254, 0.2) !important;
  }
}
.flex_sb{
    display:flex;
    justify-content: space-between;
}