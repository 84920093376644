.map_left_info_container {

    // 自定义tabs 操作按钮
    .current_render_tabs {
        position: relative;

        .ant-tabs-tab {
            padding: 1px 20px !important;
        }

        .ant-tabs-ink-bar {
            height: 0 !important;
        }

        .custom_tab_label {
            font-size: 14px;
            padding: 11px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            color: rgba(0, 0, 0, 0.4);
            font-weight: 400;
            line-height: 1.1;

            .partition_active {
                display: inline-block;
                width: 20px;
            }

            .anticon {
                margin-right: 0 !important;
                margin-bottom: 4px;
                width: 20px;
            }
        }

        .ant-tabs-tab {
            width: 20px;
            // margin-bottom: 100px;
            background: #fff;
            color: #1c6cdc;
            writing-mode: vertical-lr;
            box-shadow: 0px 4px 20px 0px rgba(28, 108, 220, 0.2);
            border-radius: 0 4px 4px 0;
            backdrop-filter: blur(6px);
        }

        .ant-tabs-tab-active {
            background-color: #1c6cdc;

            .custom_tab_label {
                color: #fff;
                font-weight: 400;
            }

            .ant-tabs-tab-btn {
                color: #fff;

                div {
                    img {
                        vertical-align: top;
                    }
                }
            }

            .partition {
                display: inline-block;
            }
        }
    }

    //  tabs内容样式
    &>.ant-tabs-content-holder {
        display: block;
        // background-color: #fff;
        border-left-width: 0;
        margin-left: 6px;
        width: 370px;
        max-height: calc(100vh - 94px);
        overflow: hidden;
        border-radius: 4px;
        box-shadow: 4px 4px 20px 0 rgba(28, 108, 220, 0.2);

        .ant-tabs-tabpane {
            // padding: 16px 3px 20px 16px !important;

            padding: 0 !important;
        }
    }

    .custom_search_style {
        .search_input {
            border-width: 0;
            background-color: #FAFAFA;
            border-radius: 4px;
            color: #f5f5f5;



            .ant-input {
                background-color: #FAFAFA;
                width: 338px;
            }
        }
    }

    .select_group {
        padding: 10px 17px 0 17px;
    }

    .item_container {
        // padding: 0 14px 6px 16px;

        margin: 12px 0 10px 0;

        .item_empty {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 208px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.2);

        }

        .item_empty_enterpress {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 243px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.2);

        }



        .item_wrap {
            padding: 12px 12px 6px 12px;
            border-radius: 2px;
            border: 1px solid #E6E6E6;
            margin-bottom: 8px;
            min-height: 80px;
            box-sizing: border-box;
            position: relative;

            .item_right_corner {
                cursor: pointer;
                position: absolute;
                top: 0px;
                right: 0px;
                // 三角形
                width: 0;
                height: 0;
                border-width: 0 30px 26px 0;
                border-style: solid;
                border-color: transparent rgba(1, 102, 255, 0.1) transparent transparent;

                .item_icon {
                    position: absolute;
                    top: -5px;
                    right: -28px;
                    width: 10px;
                }


            }

            .item_title {
                display: flex;
                justify-content: space-between;
                line-height: 20px;
                font-size: 14px;
                margin-bottom: 4px;
            }

            .item_content {
                font-weight: 400;
                color: rgba(0, 0, 0, 0.4);
                line-height: 18px;
                font-size: 12px;

                &>span {
                    padding: 0 4px;
                    margin-right: 4px;
                    background: #F5F8FA;
                    border-radius: 2px;
                    border: 1px solid #F0F0F0;
                }
            }

            .item_tiem {
                margin-top: 4px;
                display: flex;
                justify-content: end;
                text-align: right;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.4);
                line-height: 14px;
                font-size: 10px;


            }
        }

        .notTags {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px;

        }

        .item_actived {
            background: #EBF3FF;
            border: 1px solid #0166FF;
        }

        .item_agent {
            border-left: 3px solid #1C6CDC;

        }

        .item_self {
            border-left: 3px solid #2DA641;
        }

        .item_visible {
            border-left: 3px solid #39BBBB;
        }
    }
}

.list_wrap {
    padding: 0 4px;
    // height: calc(100vh - 164px);
    // overflow-y: auto;
}

.list_content {
    max-height: calc(100vh - 204px);
    overflow-y: auto;
    // padding: 0px 8px 0 12px;
}



.hiddle_tabs_holder {
    &>.ant-tabs-content-holder {
        background-color: #fff;
        display: none;
        // width: 370px;
    }
}



.enterpress_name {
    display: flex;
    // justify-content: center;
    border-bottom: 1px solid #EBEBEB;
    font-size: 16px;
    font-weight: 500;
    padding: 16px;
}

.close_leftcontent {
    background-color: #fff;
    position: absolute;
    padding: 12px 5px;
    top: 50%;
    right: -402px;
    transform: rotate(180deg);

    img {
        width: 16px;
    }
}

.ant-menu-vertical-left {
    border-right-width: 0 !important;
}

.custom_person {
    display: flex;
    justify-content: space-between;
    padding: 10px 12px;
    background-color: #fff;
    // margin-bottom: 10px;

}

.list_info_wrap {
    background-color: #fff;
    min-height: 410px;
}

.enterpress_content {
    background-color: #fff;
    padding: 0 8px 12px 0;
    min-height: 394px;
}

.show_personage_bill_info {
    max-height: calc(100vh - 258px);
}

.popover_custom_corner {
    left: 400px !important;
}

.ant-popover-inner-content {
    padding: 0 !important;
}

.item_right_corner_content {
    padding: 8px 10px;
    overflow: auto;
    box-sizing: border-box;
    /* 边框和内边距包含在元素宽度内 */
    width: 216px;
    max-height: 96px;
    font-weight: 400;
    font-size: 12px;
    color: #0166FF;
    line-height: 18px;
    text-align: left;
    font-style: normal;
    background: #E5EFFF;
    border-radius: 4px;
    border: 1px solid #3988FF;
}