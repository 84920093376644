.boss_select {

  &>.ant-menu-item,
  &>.ant-menu-submenu {
    border-bottom: 1px solid #EBEBEB;
  }



  // transform: translate(56px, -12px);
  // position: absolute;
  // top: 16px;


}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 1px solid transparent !important;
}