.compress_drawer_header {
    margin-top: 37px !important;

    .ant-drawer-content-wrapper {
        height: calc(100vh - 83px)
    }
}

.box {
    position: absolute;
    // z-index: 1000;
    width: 400px;
    height: 300px;
    box-sizing: border-box;
    // border: 100px solid rgba(27, 26, 26, 0.776);

    img {
        position: absolute;
        z-index: 1000;
    }
}

.map_drawer {
    .ant-drawer-header {
        padding: 10px;
    }

    .ant-descriptions-item-label {
        font-weight: 400;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
    }

    .ant-descriptions-item-content {
        font-weight: 400;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
    }

    .storage_title {
        font-weight: 600;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.8);
        margin-bottom: 10px;
    }

    .storage_steps {
        margin-top: 8px;
        padding: 32px 2px 12px;
        background: rgba(0, 0, 0, 0.04);
        width: 100%;
        border-radius: 4px;




        .ant-steps-item-icon {
            padding: 0;
            margin: 0;
            // min-width: 90px;
            // max-width: 100px;
            background: #fff;
            // margin-left: 0 !important;

            .ant-steps-icon {
                font-size: 18px !important;
                position: relative;

                .last_child {
                    position: relative;
                    right: 0;
                    top: -20px;

                    // margin-left: 10px;
                    .icon_quantity {
                        font-size: 12px;
                        position: absolute;
                        top: -20px;
                        left: 0;
                        transform: translateX(-50%);
                        min-width: 100px;
                    }
                }
            }


        }

        .ant-steps-item-tail:after {
            border: 1px dashed rgba(28, 108, 220, 1);

        }

        // 横线
        .ant-steps-item-tail {
            padding: 0;
        }

        .steps_icon_wrap {
            background: #fff;
        }
    }
}

.detail_steps {
    .ant-steps-item-container {
        display: flex;
        align-items: start;
    }

    .ant-steps-item-content {
        min-height: 28px !important;
        width: 100%;
    }

    .ant-steps-icon,
    .ant-steps-item-icon {
        width: auto !important;
        height: auto !important;
        font-size: 20px !important;
        line-height: 10px !important;
        margin-top: 10px;
    }

    .node_icon_container {
        transform: translateX(5px);
    }

    .icon_container {
        transform: translateX(10px);
    }

    .ant-steps-item-tail {
        margin-top: 15px;
        padding: 0 !important;
    }

    .ant-steps-item-tail::after {
        width: 2px !important;
        background-color: rgba(21, 91, 212, 1) !important;
    }

    .steps_icon_wrap {
        background-color: #fff;
        z-index: 999;
        height: 18px;
    }

    .node_step {
        &>span {
            font-weight: 600;
            font-size: 14px;
            color: #155BD4;
        }

        &>span:nth-child(1) {
            padding-right: 4px;
        }

        &>span:nth-child(2) {
            color: rgba(0, 0, 0, 1);
        }
    }

    .ant-steps-item-title {
        width: 100%;
    }

    .un_node_step {
        &>span {
            font-weight: 600;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.8);
        }
    }

    .step_description {
        padding: 8px 12px;
        background: rgba(0, 0, 0, 0.04);
        border-radius: 4px;
        font-size: 12px;
    }


}

.detail_steps_title {
    background: rgba(228, 238, 255, 1);
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    color: #155BD4;
    padding: 3px 12px;
    border-radius: 12px;

    i {
        background: #155BD4;
        padding: 1px;
        border-radius: 2px;
        margin: 0 2px;
    }
}

.circle_icon {
    i {
        background: #155BD4;
        padding: 1px;
        border-radius: 2px;
        margin: 0 2px;
    }
}

.detail_goods_info {
    margin-left: 22px;
    padding: 8px 12px;
    background: rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    font-size: 12px;

    span {
        color: rgba(0, 0, 0, 0.45);

    }
}