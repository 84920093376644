.map_left_tabs {
  .current_tab_bar {
    .ant-tabs-nav:before {
      margin-top: 1px;
      border-bottom: 2px solid #1c6cdc;
    }

    .ant-tabs-nav {
      margin: 0 0 13px;
    }

    .ant-tabs-tab {
      width: 60px;
      height: 36px;
      justify-content: center;
      background: rgba(242, 248, 255, 0.4);
      border-radius: 4px 4px 0px 0px;
      border: 1px solid #1c6cdc;
      backdrop-filter: blur(6px);

      .ant-tabs-tab-btn {
        color: #1c6cdc;
      }
    }

    .ant-tabs-tab+.ant-tabs-tab {
      margin: 0 0 1px 2px;
    }

    .ant-tabs-tab-active {
      background: #1c6cdc;
      color: #fff;
      font-weight: 400;
      line-height: 1.1;

      .ant-tabs-tab-btn {
        color: #fff;
      }
    }
  }
}

.map_left_container {
  .show_left_content {
    .show_content_btn {
      width: 20px;
      height: 36px;
      background: #ffffff;
      border-radius: 0px 4px 4px 0px;
      backdrop-filter: blur(6px);
      position: absolute;
      top: 50%;
      right: -20px;

      .arrow_right {
        transform: rotateY(180deg);
      }
    }
  }

  .hiddle_left_content {
    .show_content_btn_left {
      width: 20px;
      height: 36px;
      background: #ffffff;
      border-radius: 0px 4px 4px 0px;
      backdrop-filter: blur(6px);
      position: fixed;
      top: 50%;
      left: 0;
    }

    .ant-tabs-content-holder {
      display: none;
      overflow: hidden;
      border-radius: 4px;
    }
  }
}

.map_left_tabs_content {
  width: 370px;
  // height: calc(100vh - 110px);
  padding-top: 10px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 4px;
  backdrop-filter: blur(6px);

  .top_control {
    // padding-top: 10px;
    display: flex;
    // line-height: 54px;
    align-items: center;
    padding: 10px 0;

    img {
      margin: 0 5px;
    }
  }

  .ant-tabs-tab {
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.4);
    line-height: 20px;
  }

  .ant-tabs-tab+.ant-tabs-tab {
    margin: 0 0 0 16px;
  }

  .empty_list {
    text-align: center;
    margin-top: 350px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.4);
    line-height: 20px;
  }
}

// 日期 组件宽度 默认父级
.ant-picker {
  display: flex !important;
}

.map_tabs_content {

  // control tab
  .current_render_tabs {
    .ant-tabs-tab {
      padding: 1px 20px !important;
    }

    .ant-tabs-ink-bar {
      height: 0 !important;
    }

    .custom_tab_label {
      font-size: 14px;
      padding: 11px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: rgba(0, 0, 0, 0.4);
      font-weight: 400;
      line-height: 1.1;

      .partition_active {
        display: inline-block;
        width: 20px;
      }

      .anticon {
        margin-right: 0 !important;
        margin-bottom: 4px;
        width: 20px;
      }
    }

    .ant-tabs-tab {
      width: 20px;
      // margin-bottom: 100px;
      background: #fff;
      color: #1c6cdc;
      writing-mode: vertical-lr;
      box-shadow: 0px 4px 20px 0px rgba(28, 108, 220, 0.2);
      border-radius: 0 4px 4px 0;
      backdrop-filter: blur(6px);
    }

    .ant-tabs-tab-active {
      background-color: #1c6cdc;

      .custom_tab_label {
        color: #fff;
        font-weight: 500;
      }

      .ant-tabs-tab-btn {
        color: #fff;

        div {
          img {
            vertical-align: top;
          }
        }
      }

      .partition {
        display: inline-block;
      }
    }
  }

  // tab content
  &>.ant-tabs-content-holder {
    display: block;
    background-color: #fff;
    border-left-width: 0;
    margin-left: 6px;
    width: 370px;
    height: calc(100vh - 94px);
    overflow: hidden;
    border-radius: 4px;
    box-shadow: 4px 4px 20px 0 rgba(28, 108, 220, 0.2);

    .ant-tabs-tabpane {
      // padding: 16px 3px 20px 16px !important;

      padding: 0 !important;
    }
  }
}

.hiddle_tabs_holder {
  &>.ant-tabs-content-holder {
    display: none;
    // width: 370px;
  }
}

.list_wrap {
  padding: 0 4px;
}

.list_content {
  min-height: 243px;
  overflow-y: auto;
  padding: 0px 8px 12px 12px;
}

.list_content_unsearch_header {
  height: calc(100vh - 60px);
  overflow-y: auto;
  padding-bottom: 20px;
}

// .ant-tabs-tabpane
.custom_steps::-webkit-scrollbar,
.list_content::-webkit-scrollbar,
.vusual_list::-webkit-scrollbar,
.news_container .ant-spin-container::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.custom_steps::-webkit-scrollbar-track,
.list_content::-webkit-scrollbar-track,
.vusual_list::-webkit-scrollbar-track,
.news_container::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 10 px;
  // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  opacity: 0.2;
  //  background: fade(@primary-color, 60%);
}


.list_content::-webkit-scrollbar-thumb,
.vusual_list::-webkit-scrollbar-thumb,
.news_container .ant-spin-container::-webkit-scrollbar-thumb,
.custom_steps::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 0;
  // background: red;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.16);
}

.custom_tabs_content {
  .ant-divider-horizontal {
    margin: 12px 0 4px 0;
  }

  .custom_search_style {
    padding: 12px 12px 1px 16px !important;
  }
}