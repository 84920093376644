.marketRight {
    width: 307px;
    height: 42px;
    line-height: 42px;
    background: #FFFFFF;
    box-shadow: 4px 4px 20px 0px rgba(28, 108, 220, 0.2);
    // border-radius: 4px;


    .marketTop {
        display: flex;
        justify-content: space-between;
        padding: 0px 16px;
    }

    .market {
        font-size: 14px;
        font-family: PingFangSC,
            PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.8);
        cursor: pointer;

        .marketTopIcon {
            width: 16px;
            height: 16px;
            margin-left: 10px;
        }
    }

    .rightEdit {
        font-size: 12px;
        font-family: PingFangSC,
            PingFang SC;
        font-weight: 400;
        color: #1C6CDC;
        cursor: pointer;
    }

    .marketConten {
        padding: 0px 16px 10px 16px;
        background-color: #FFFFFF;
        max-height: 64.8vh;
        overflow: auto;
        border-radius: 0px 0px 4px 4px;

        .indexBox {
            margin-bottom: 8px;
            display: flex;
            // justify-content: space-between;
            padding: 0px 8px;
            height: 36px;
            line-height: 36px;
            background: rgba(0, 0, 0, 0.04);
            border-radius: 2px;
            border: 1px solid rgba(0, 0, 0, 0.06);

            .indexBoxLeft {
                width: 150px;
                cursor: pointer;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 12px;
                font-family: PingFangSC,
                    PingFang SC;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.8);
            }

            .indexBoxRight {
                width: 150px;
                font-size: 12px;
                font-family: PingFangSC,
                    PingFang SC;
                font-weight: 400;
                color: #0EBF98;
                text-align: right;
            }
        }

    }

    .EmptyBox {
        display: flex;
        justify-content: center;
        text-align: center;
        margin-top: 40px;
    }

    .EmptyFontBox {
        font-size: 12px;
        font-family: PingFangSC,
            PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.4);
    }

    // .EmptyIconBox{
    //    margin-top: 50px; 
    //    margin-left: 70px;
    // }

    .minusIconClass {
        margin-top: 10px;
        width: 16px;
        height: 16px;
    }
}