.App {

    height: 100vh;
}

.fsb {
    display: flex;
    justify-content: space-between;
}

.pe {
    pointer-events: none;
}


.scrollbar_custom::-webkit-scrollbar,
.scrollbar_custom::-webkit-scrollbar,
.scrollbar_custom::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.scrollbar_custom::-webkit-scrollbar-track,
.scrollbar_custom::-webkit-scrollbar-track,
.scrollbar_custom::-webkit-scrollbar-track {
    background-color: #fff;
}

.scrollbar_custom::-webkit-scrollbar-thumb,
.scrollbar_custom::-webkit-scrollbar-thumb,
.scrollbar_custom::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.16);
}